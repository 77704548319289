<body class="flex min-h-screen lg:py-4 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
  <div class="flex w-full p-4 justify-center">
    <div class="flex items-center">
      <div class="mb-0 sm:mx-auto sm:w-[410px] card shadow-none border-none bg-white h-fit">
        <div class="px-8 sm:!px-10 !py-12 card-body">
          <div class="flex flex-col items-center justify-center text-center mb-4">
            <span
              class="group-data-[sidebar-size=sm]:hidden font-kanit text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold italic text-[#1E293B] mb-6">
              SKETCHPLAY
            </span>

            <ng-container>
              <span class="text-black">
                To confirm your account, please enter the security code. We sent
                this to both your email and mobile number.
              </span>
            </ng-container>

          </div>

          <ng-container>

<form action="" class="mt-10" id="signInForm" formGroup="loginForm">
    <div class="flex justify-center">
      <ng-otp-input class="custom-otp-input" (onInputChange)="onOtpChange($event)" id="otpInput" #ngOtpInput [config]="config"></ng-otp-input>
    </div>

    <div class="otp-controls mt-2 !justify-end">
      <!-- Countdown timer on the left -->
      <p  class="countdown text-black" *ngIf="isResetDisabled">Reset OTP in {{ countdown }} seconds</p>
      <!-- Reset OTP button on the right -->
      <button type="button" [disabled]="isResetDisabled" (click)="resetOtp();" [ngClass]="isResetDisabled ? 'opacity-60':'cursor-pointer reset-button font-bold text-[#64748b] hover:text-[#3b82f6] transition-all duration-150 ease-linear' ">
        Resend OTP
      </button>
    </div>

    <div class="mt-24" >
      <button [disabled]="!isOtpCorrect"
        class="w-full text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        (click)="navigateToLogin($event)"
        
        >
        {{ submitted ? 'Loading...' : 'Enter OTP' }}
        
      </button>
    </div>
  </form>
  </ng-container>
  <div class="mt-8 text-center">
    <span>

    </span>
    <p class="text-black mt-24 border-t-2 border-[#e2e8f0] pt-8">
      © 2024 Crafted with
      <i class="ri-heart-fill heart-icon text-[#ff0000]"></i> by Lab3, Interactive, inc.
      <br />
      DBA as
      <a href="https://sketchplay.com" target="_blank"
        class="font-semibold underline transition-all duration-150 ease-linear dark:text-zink-200 dark:hover:text-custom-500">
        Sketchplay.
      </a>
    </p>
  </div>
  </div>
  </div>
  </div>
  </div>
  </body>