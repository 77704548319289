<body
  class="flex items-center justify-center min-h-screen py-16 bg-cover bg-auth-pattern dark:bg-auth-pattern-dark font-public">
  <div
    class="mb-0 border-none shadow-none lg:w-[500px] card bg-white/70 dark:bg-zink-500/70">
    <div class="!px-10 !py-12 card-body">
      <img
        src="../../../assets/images/info.png"
        alt=""
        class="mx-auto w-[13rem]" />
      <div class="mt-8 text-center">
        <h4 class="mb-2 text-purple-500">PAGE NOT FOUND</h4>
        <a
          routerLink="/"
          class="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          ><lucide-angular
            name="home"
            [class]="'inline-block size-3 ltr:mr-1 rtl:ml-1'"></lucide-angular>
          <span class="align-middle">Back to Home</span></a
        >
      </div>
    </div>
  </div>
</body>
