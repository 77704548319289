import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { StoredDataService } from '@app/core/services/storedData.service';
import { NgSelectModule } from '@ng-select/ng-select';

interface forgotPassEmail {
  email: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule]
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassForm: FormGroup = new FormGroup({});
  submitted = false;
  isFormSubmitted: boolean = false;

  emailDetail?: forgotPassEmail;
  userData: any[] = []

  constructor(private router: Router, private authenticationService: AuthenticationService, private storedDataService: StoredDataService) { }
  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.forgotPassForm = new FormGroup({
      email: new FormControl(this.emailDetail?.email.trim(), [
        Validators.required,
        Validators.pattern(/^\s*[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})\s*$/)
      ]),
      userId: new FormControl(null)
    });
  }

  navigateToLogin() {
    this.router.navigate(['/account-login']);
  }

  onForgotPassSubmit() {
    this.isFormSubmitted = true;
    if (this.forgotPassForm.invalid) {
      this.forgotPassForm.markAllAsTouched();
      return;
    }
    this.submitted = true;
    const emailFromInput = this.forgotPassForm.value.email;
    const trimmedEmailValue = emailFromInput ? emailFromInput.trim() : null;
    this.storedDataService.setData(trimmedEmailValue);
    this.authenticationService.forgotPassword({ email: trimmedEmailValue }).subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.submitted = false;
          this.forgotPassForm.reset();
          if (response.data) {
            this.forgotPassForm.controls['userId'].setValidators(
              Validators.compose([Validators.required]));
            this.forgotPassForm.get('email')?.setErrors(null);
            this.userData = response.data
            showAlertMessage(response.message ?? "Success", "success");
          } else {
            showAlertMessage(response.message ?? "Success", "success", () => this.router.navigate(['/otp']));
          }
        }
      }, (error) => {
        this.submitted = false;
        showAlertMessage(error ?? "Something went wrong!", "error")
      }
    )

  }

}
