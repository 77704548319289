<header
  id="page-topbar"
  (window:scroll)="windowScroll()"
  class="ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm group-data-[layout=horizontal]:ltr:left-0 group-data-[layout=horizontal]:rtl:right-0 fixed right-0 z-[1000] left-0 print:hidden group-data-[navbar=bordered]:m-4 group-data-[navbar=bordered]:[&.is-sticky]:mt-0 transition-all ease-linear duration-300 group-data-[navbar=hidden]:hidden group-data-[navbar=scroll]:absolute group/topbar group-data-[layout=horizontal]:z-[1004]">
  <div class="layout-width">
    <div
      class="flex items-center px-4 mx-auto bg-topbar border-b-2 border-topbar group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand shadow-md h-header shadow-slate-200/50 group-data-[navbar=bordered]:rounded-md group-data-[navbar=bordered]:group-[.is-sticky]/topbar:rounded-t-none group-data-[topbar=dark]:dark:bg-[#132337] group-data-[topbar=dark]:dark:border-zink-700 dark:shadow-none group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-zink-500 group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-md group-data-[navbar=bordered]:shadow-none group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none group-data-[layout=horizontal]:shadow-none group-data-[layout=horizontal]:dark:group-[.is-sticky]/topbar:shadow-none">
      <div
        class="flex items-center w-full group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl navbar-header group-data-[layout=horizontal]:ltr:xl:pr-3 group-data-[layout=horizontal]:rtl:xl:pl-3">
        <!-- LOGO -->
        <div
          class="items-center justify-center hidden px-5 text-center h-header group-data-[layout=horizontal]:md:flex group-data-[layout=horizontal]:ltr::pl-0 group-data-[layout=horizontal]:rtl:pr-0">
          <a routerLink="/">
            <span class="hidden">
              <img src="assets/images/logo-sm.png" alt="" class="h-6 mx-auto" />
            </span>
            <span
              class="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
              <img
                src="assets/images/logo-dark.png"
                alt=""
                class="h-6 mx-auto" />
            </span>
          </a>
          <a
            routerLink="/"
            class="hidden group-data-[topbar=dark]:block group-data-[topbar=brand]:block">
            <span
              class="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
              <img src="assets/images/logo-sm.png" alt="" class="h-6 mx-auto" />
            </span>
            <span
              class="group-data-[topbar=dark]:block group-data-[topbar=brand]:block">
              <img
                src="assets/images/logo-light.png"
                alt=""
                class="h-6 mx-auto" />
            </span>
          </a>
        </div>

        <button
          type="button"
          (click)="changeSidebar()"
          class="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear bg-topbar rounded-md btn hover:bg-slate-100 group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:border-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:md:hidden hamburger-icon"
          id="topnav-hamburger-icon">
          <lucide-angular
            name="chevrons-left"
            [class]="
              'w-5 h-5 group-data-[sidebar-size=sm]:hidden'
            "></lucide-angular>
          <lucide-angular
            name="chevrons-right"
            [class]="
              'hidden w-5 h-5 group-data-[sidebar-size=sm]:block'
            "></lucide-angular>
        </button>

        <!-- <div
          class="relative hidden ltr:ml-3 rtl:mr-3 lg:block group-data-[layout=horizontal]:hidden group-data-[layout=horizontal]:lg:block">
          <input
            type="text"
            class="py-2 pr-4 text-sm text-topbar-item bg-topbar border border-topbar-border rounded pl-8 placeholder:text-slate-400 form-control focus-visible:outline-0 min-w-[300px] focus:border-blue-400 group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-border-dark group-data-[topbar=dark]:placeholder:text-slate-500 group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-border-brand group-data-[topbar=brand]:placeholder:text-blue-300 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:border-zink-500 group-data-[topbar=dark]:dark:text-zink-100"
            placeholder="Search for ..."
            autocomplete="off" />

          <lucide-angular
            name="search"
            [class]="
              'size-4 absolute left-2.5 top-2.5 text-topbar-item fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:fill-zink-600'
            "></lucide-angular>
        </div> -->

        <div class="flex gap-3 ms-auto items-center">
          <a (click)="changeLayoutMode()" class="mr-1 inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark cursor-pointer" id="light-dark-mode">
            <lucide-angular name="sun" [class]="'icon-sun size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'"></lucide-angular>
            <lucide-angular name="moon" [class]="'icon-moon size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'"></lucide-angular>
          </a>
          <div
            mndropdown
            [placement]="'bottom-start'"
            class="relative flex items-center dropdown h-header">
            <button
              mndropdownToggle
              type="button"
              class="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown">
              <div class="bg-pink-100 rounded-full">
                <img
                  src="assets/images/users/user-profile.png"
                  alt=""
                  class="size-[37.5px] rounded-full" />
              </div>
            </button>
            <div
              mndropdownMenu
              class="userDropdownMenu absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem] dark:bg-black border dark:border-zink-600"
              aria-labelledby="dropdownMenuButton">
              <a href="javascript:void(0);" class="flex gap-3 mb-3">
                <div class="relative inline-block shrink-0">
                  <div class="rounded-full bg-slate-100 dark:bg-zink-500">
                    <img
                      src="assets/images/users/user-profile.png"
                      alt=""
                      class="size-12 rounded-full" />
                  </div>
                </div>
                <div>
                  <h6 class="mb-0 text-16">
                    {{ userData?.firstName }}
                    {{ userData?.lastName }}
                  </h6>
                  <p class="text-slate-500 dark:text-zink-300">
                    {{ userData?.roles.includes('superAdmin') ? 'Super Admin' : '' }}
                    {{ userData?.roles.includes('sketchplayStaff') ? 'Sketchplay Staff' : '' }}
                  </p>
                </div>
              </a>
              <ul class="border-t border-slate-200 dark:border-zink-500 pt-2 mt-2">
                <!-- <li>
                  <a
                    class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                    routerLink="/pages-account"
                    ><lucide-angular
                      name="user"
                      [class]="
                        'inline-block size-4 ltr:mr-2 rtl:ml-2'
                      "></lucide-angular>
                    Profile</a
                  >
                </li> -->
                <!-- <li class="pt-2 pl-3">
                  <a (click)="changeLayoutMode()" class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500 cursor-pointer" id="light-dark-mode">
                    <lucide-angular name="sun" [class]="'inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand me-1'"></lucide-angular>
                    Dark Mode
                  </a>
                </li> -->
                <li class="pt-2 pl-3">
                  <div (click)="changePassword()"
                      class="cursor-pointer block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-[#64748B] dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-white dark:hover:text-custom-500 dark:focus:text-custom-500">
                      <i class="ri-user-3-line text-lg mr-2 ml-1 ltr:mr-1 rtl:ml-1"></i>Change Password</div>
                </li>
                <li class="pt-2 pl-3">
                  <a (click)="signOut()" class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500" routerLink="/auth-logout-basic">
                    <lucide-angular name="log-out" [class]="'inline-block size-4 ml-1 ltr:mr-1 rtl:ml-1'"></lucide-angular>
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<drawer id="cartSidePenal" [position]="'drawer-end'">
  <div
    class="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500">
    <div class="grow">
      <h5 class="mb-0 text-16">Shopping Cart</h5>
    </div>
    <div class="shrink-0">
      <button
        dismissDrawer
        [DrawerDismiss]="'cartSidePenal'"
        class="transition-all duration-150 ease-linear text-slate-500 hover:text-slate-800">
        <lucide-angular name="x" [class]="'size-4'"></lucide-angular>
      </button>
    </div>
  </div>
</drawer>
