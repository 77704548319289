export const environment = {
  production: true,
  API: 'https://api.sketchplay.io/',
  defaultauth: 'real',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  stripe: {
    stripePublishableKey: '',
  },
  smsfeatureId: 15
};
