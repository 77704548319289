import { MenuItem } from './menu.model';

let MENU: MenuItem[] = [];

function updateMenu(updatedMenu: MenuItem[]) {
  MENU = updatedMenu;
}

export {
  MENU,
  updateMenu
};

