import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StoredDataService {

    constructor() { }

    setData(data: any) {
        localStorage.setItem('StoredData', JSON.stringify(data));
    }

    getData() {
        const data = localStorage.getItem('StoredData');
        return data ? JSON.parse(data) : null;
    }

    removeData() {
        localStorage.removeItem('StoredData');
    }
}
