import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/account/login/login.service';
import { showAlertMessage } from '@app/core/helpers/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { UserProfileService } from '../../core/services/user.service';
import { Register, RegisterFailure, RegisterSuccess, login, loginFailure, loginSuccess, logout, logoutSuccess } from './authentication.actions';
@Injectable()
export class AuthenticationEffects {

  errorMessage(errorMsg: string) {
    // Swal.fire({
    //   title: 'Oops...',
    //   text: errorMsg,
    //   icon: 'error',
    //   customClass: {
    //     confirmButton: 'text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20',
    //   },
    //   buttonsStyling: true,
    //   showCloseButton: false,
    // }).then(() => {
    //   //location.reload();
    // })
    showAlertMessage(errorMsg ?? "Something went wrong!", "error");
  }

  Register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Register),
      exhaustMap(({ email, username, password }) => {
        if (environment.defaultauth === 'fakebackend') {
          return this.userService.register({ email, username, password }).pipe(
            map((user) => {
              this.router.navigate(['/auth/login']);
              return RegisterSuccess({ user })
            }),
            catchError((error) => of(RegisterFailure({ error })))
          );
        } else {
          return this.AuthenticationService.register(email, username, password).pipe(
            map((user) => {
              this.router.navigate(['/auth/login']);
              return RegisterSuccess({ user })
            })
          )
        }
      })
    )
  );



  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(({ email, password }) => {
        try {
          this.loginService.login(email, password).subscribe((response: any) => {
            if (response?.status) {
              let userData = response?.data ?? {};
              localStorage.setItem("token", userData?.token ?? "");
              this.AuthfakeService.setCurrentUser(userData)
              if (userData.isPasswordReset) {
                this.router.navigate(['/change-password-public']);
              } else {
                this.router.navigate(['/clients']);
              }
              loginSuccess({ user: userData });
            } else {
              this.errorMessage(response?.message ?? "Something went wrong! try again");
            }
          }, (error) => {
            this.errorMessage(error ?? "Wrong password! try again");
          })
        } catch (error: any) {
          this.errorMessage(error?.message ?? "Something went wrong! try again");
          catchError((error) => of(loginFailure({ error })));
        }
        // Handle the case where none of the conditions match
        return of(loginFailure({ error: 'Unsupported authentication method' }));
      })
    )
  );


  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => {
        // Perform any necessary cleanup or side effects before logging out
      }),
      exhaustMap(() => of(logoutSuccess()))
    )
  );

  constructor(
    @Inject(Actions) private actions$: Actions,
    private AuthenticationService: AuthenticationService,
    private AuthfakeService: AuthfakeauthenticationService,
    private userService: UserProfileService,
    private router: Router,
    private loginService: LoginService,
  ) { }

}
