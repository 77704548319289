import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private authfackservice: AuthfakeauthenticationService,
    private ngxSpinnerService: NgxSpinnerService,
    private tokenStorageService: TokenStorageService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authfackservice.currentUserValue;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error?.status === 401 || error === 'Expired Token' || error === 'Invalid token') {
          // this.ngxSpinnerService.hide()
          this.tokenStorageService.signOut();
          this.authfackservice.logout(); // Log out the user
        }
        return throwError(error); // Propagate the error
      })
    );
  }


}
