import { ActionReducerMap } from "@ngrx/store";
import { AuthenticationState, authenticationReducer } from "./Authentication/authentication.reducer";
import { UserReducer, UserState } from "./User/user-reducer";
import { LayoutState, layoutReducer } from "./layout/layout-reducers";


export interface RootReducerState {
    layout: LayoutState,
    authentication: AuthenticationState;
    User: UserState,
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
    layout: layoutReducer,
    authentication: authenticationReducer,
    User: UserReducer,
}
