// import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

// // Auth Services
// import { environment } from '../../../environments/environment';
// import { AuthenticationService } from '../services/auth.service';
// import { AuthfakeauthenticationService } from '../services/authfake.service';

// @Injectable({ providedIn: 'root' })
// export class AuthGuard implements CanActivate {
//   constructor(
//     private router: Router,
//     private authenticationService: AuthenticationService,
//     private authFackservice: AuthfakeauthenticationService
//   ) { }

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//     if (environment.defaultauth === 'firebase') {
//       const currentUser = this.authenticationService.currentUser();
//       if (currentUser) {
//         // logged in so return true
//         return true;
//       }
//     } else {
//       const currentUser = this.authFackservice.currentUserValue;
//       if (currentUser) {
//         // logged in so return true
//         return true;
//       }
//       // check if user data is in storage is logged in via API.
//       if (localStorage.getItem('currentUser')) {
//         return true;
//       }
//     }
//     // not logged in so redirect to login page with the return url
//     this.router.navigate(['/account-login']);
//     return false;
//   }
// }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// Auth Services
import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this.authFackservice.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }
    // check if user data is in storage is logged in via API.
    if (localStorage.getItem('currentUser')) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/account-login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
